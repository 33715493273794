<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      class="cursor-pointer rounded-10 overflow-hidden py-2 rounded-10"
      elevation="3"
      :width="width"
      :max-width="maxWidth"
      color="white"
      v-bind="$attrs"
      @click.stop="emit_action('viewProduct')"
    >
      <v-card-title class="card-header" v-show="hover">
        <v-spacer />
        <v-menu open-on-hover bottom offset-y v-if="can_do_action">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              depressed
              icon
              color="light"
              v-bind="attrs"
              v-on="on"
              class="ml-1 primary"
              @click.stop=""
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="canDelete(item)"
              @click.stop="emit_action('deleteProduct')"
            >
              <v-list-item-title class="primary--text">
                <v-icon left color="error">mdi-delete-circle-outline</v-icon>
                Delete
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canEdit(item)"
              @click.stop="emit_action('editProduct')"
            >
              <v-list-item-title class="primary--text">
                <v-icon left color="primary">mdi-circle-edit-outline</v-icon>
                Edit
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canShare(item) && hasShare"
              @click.stop="emit_action('shareProduct')"
            >
              <v-list-item-title>
                <v-icon left>mdi-share-all</v-icon>
                Share
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-badge
          :content="item.likes_count"
          color="secondary"
          :value="item.likes_count"
          overlap
        >
          <v-btn
            icon
            fab
            small
            @click.stop="emit_action('addToLikes')"
            class="ml-1 primary"
          >
            <v-icon :color="item.is_liked ? 'red' : 'white'">
              mdi-cards-heart
            </v-icon>
          </v-btn>
        </v-badge>
      </v-card-title>
      <v-carousel
        v-model="carousel"
        :continuous="true"
        :cycle="true"
        :show-arrows="false"
        hide-delimiters
        height="200"
      >
        <v-carousel-item v-for="(image, i) in item.previews" :key="i">
          <v-sheet
            height="100%"
            tile
            color="#dedede"
            class="d-flex flex-column"
          >
            <v-row
              style="background: #ffffff"
              class="fill-height flex-column"
              align="center"
              justify="center"
            >
              <Attachment
                :item="image"
                prefer-thumb
                @attachment-clicked="emit_action('viewProduct')"
                class="ma-auto pa-1"
              ></Attachment>
              <span
                class="py-1 fs-12 templates-count primary--text fw-700"
                v-if="item.previews.length > 1"
              >
                {{ carousel + 1 }} of {{ item.previews.length }}
              </span>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <div class="title-card d-flex py-2 px-1 align-center" v-if="hasTitle">
        <span class="fs-13 text-center">
          {{ item.title | ucwords | truncate(30) }}
        </span>
      </div>
      <div
        class="title-card py-1"
        v-if="hover && hasPrice && canAddPrice(item)"
      >
        <v-btn
          @click.stop="emit_action('addPrice')"
          small
          depressed
          color="primary"
          class="text-none"
        >
          Add Price
        </v-btn>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { offers_mixin } from '../mixin'
import _cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
  inheritAttrs: false,
  name: 'ProductCard',
  mixins: [offers_mixin],
  props: {
    product: Object,
    ratio: { type: Number, default: 1 },
    hasTitle: { type: Boolean, default: true },
    hasShare: { type: Boolean, default: false },
    hasPrice: { type: Boolean, default: false },
    maxWidth: { type: [String, Number], default: 250 },
    height: { type: [String, Number], default: 200 },
    width: { type: [String, Number], default: 'auto' }
  },
  data: () => ({
    item: null,
    carousel: 0
  }),
  computed: {
    ...mapGetters(['user']),
    can_do_action() {
      return !!(
        this.canDelete(this.item) ||
        this.canEdit(this.item) ||
        (this.canShare(this.item) && this.hasShare)
      )
    }
  },
  watch: {
    product: {
      handler(val) {
        this.item = _cloneDeep(val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    emit_action(action) {
      this.$emit(action, this.item)
    }
  }
}
</script>

<style scoped lang="scss">
.card-header {
  width: 100%;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  z-index: 2;
}

.title-card {
  width: 100%;
  color: #223a6b !important;
  display: flex;
  justify-content: center;
  background: transparent;
  span {
    font-weight: 700 !important;
    font-size: 19px !important;
  }
}

.templates-count {
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
}
</style>
